import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import useStyles from "../../styles/StyledComponents";
import PropTypes from "prop-types";

PowerPackForm.propTypes = {
  dispatch: PropTypes.func,
  goToPaymentForm: PropTypes.func,
  powerPacks: PropTypes.number,
  powerPackPrice: PropTypes.number
}

export default function PowerPackForm(props) {
  let { h2, hr, next, nextContainer } = useStyles();
  let { dispatch, goToPaymentForm, powerPacks, powerPackPrice } = props;

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h2' className={h2}>Power Packs</Typography>
        <Typography variant='subtitle1'>${powerPackPrice} each. Includes 1 Raffle Ticket, Tee Shot from Lady's Tee, and 2 Mulligans</Typography>
        <hr className={hr} />
      </Grid>
      <Grid item>
        <InputLabel id='power-pack-label'>Power Packs</InputLabel>
          <Select fullWidth value={powerPacks} onChange={(e) => dispatch({type: 'UPDATE_POWERPACKS', payload: e.target.value})} labelId='power-pack-label'>
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
      </Grid>
      <Grid item className={nextContainer}>
        <Button
          fullWidth
          className={next}
          style={{marginBottom: 50}}
          onClick={() => goToPaymentForm()}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  )
}