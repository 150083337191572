import React from 'react';
import { Grid } from '@material-ui/core';

import redwolf from '../assets/redwolf.png';

import useStyles from "../styles/StyledComponents";

export default function Footer(){
  let { footer, logo } = useStyles();

  return(
    <footer className={footer}>
      <Grid container justifyContent='center' alignItems={'center'} spacing={2} style={{margin: 0}}>
        <Grid item><img src={redwolf} alt='Red Wolf Projects logo' className={logo}/></Grid>
        <Grid item>Powered By Red Wolf Projects</Grid>
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2} style={{margin: 0}}>
        <Grid item style={{marginBottom: '10px'}}>
          <a
            style={{color: '#ffd200'}}
            href={'mailto:feedback@redwolfprojects.com?subject=Feedback For MDFOP102Golf.com'}
          >Submit Website Feedback</a>
        </Grid>
      </Grid>
    </footer>
  )
}