import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Support from '../components/Support';

import { Consumer } from '../context';
import RegistrationBody from '../components/RegistrationBody';
import Sponsors from '../components/Sponsors';
import CustomizedSnackbars from '../components/CustomSnack';

export default function RegisterPage(){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return(
    <Consumer>
      {value => {
        return (
          <Grid container direction='row'>
            {matches ? (
              <React.Fragment>
                <RegistrationBody
                  {...value}
                />
                <Support matches={matches}/>
                <Sponsors/>
              </React.Fragment>) :
              (<React.Fragment>
                <Support matches={matches}/>
                <RegistrationBody
                  {...value}
                />
                <Sponsors/>
              </React.Fragment>)}
            <CustomizedSnackbars
              open={value.error}
              variant='error'
              onClose={() => value.dispatch({type: 'CLOSE_SNACK'})}
              message={value.errorMsg}
            />
          </Grid>
        )
      }}
    </Consumer>
  )
}