import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import header_logo from '../assets/header_logo.jpeg';
import PropTypes from "prop-types";
import useStyles from "../styles/StyledComponents";
import {Link} from "react-router-dom";

ElevationScroll.propTypes = {
  children: PropTypes.object
}
//This keeps the navbar in place and makes it look like the content scrolls underneath the navbar
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

export default function Header(){
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [openDrawer, setOpenDrawer] = React.useState(false);

  let {
    appbar,
    drawerBackground,
    drawerIcon,
    drawerIconContainer,
    drawerItem,
    drawerItemSelected,
    headerLogo,
    headerLogoContainer,
    tab,
    tabContainer,
    toolbar,
    toolbarMargin
  } = useStyles();

  //Routes for external tabs
  const routes = [
    {
      name: 'MD FOP102 Home',
      link: 'https://mdfop102.com',
    },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/Frederick-County-Fraternal-Order-of-Police-102-622384904909941/',
    },
    {
      name: 'Golf Classic Brochure (PDF)',
      link: 'https://mdfop102golf.com/FOPbrochure.pdf'
    }
  ]

  //Tabs component for when the screen size is large and up
  const tabs = (
    <React.Fragment>
      <Tabs
        value={0}
        className={tabContainer}
        indicatorColor="primary"
      >
        <Tab className={tab} label='Register' component={Link} to={'/'} />
        {routes.map((route, index) => (
          <Tab
            key={`${route}${index}`}
            className={tab}
            component='a'
            href={route.link}
            rel='noreferrer noopener'
            target='_blank'
            label={route.name}
          />
        ))}
      </Tabs>
    </React.Fragment>
  );

  //Swipeable drawer for smaller screen sizes
  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: drawerBackground }}
      >
        <div className={toolbarMargin} />
        <List disablePadding>
          <ListItem
            onClick={() => setOpenDrawer(false)}
            classes={{ selected: drawerItemSelected }}
            divider
            component={Link}
            to={'/'}
            button
            selected={ true }
          >
            <ListItemText className={drawerItem} disableTypography>Register</ListItemText>
            </ListItem>
            {routes.map(route => (
            <ListItem
              key={`${route.name}`}
              onClick={() => {
                setOpenDrawer(false);
              }}
              classes={{ selected: drawerItemSelected }}
              divider
              button
              component='a'
              href={route.link}
              target='_blank'
              rel='noreferrer noopener'
            >
              <ListItemText className={drawerItem} disableTypography>
                {route.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <IconButton
        className={drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  return(
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={appbar}>
          <Toolbar disableGutters className={toolbar}>
            <Button
              className={headerLogoContainer}
              disableRipple
              component={Link}
              to={'/'}
            >
              <img src={header_logo} alt="FOP 201 logo" className={headerLogo} />
            </Button>
            {matches ? drawer : tabs}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={toolbarMargin} />
    </React.Fragment>
  )

}