import React from 'react';
import axios from 'axios';
import { find } from 'lodash';

const Context = React.createContext();

const reducer = (state, action) => {
  let golfers = state.golfers;
  switch(action.type){
    case 'UPDATE_EMAIL':
      return{
        ...state,
        email: action.payload,
      }
    case 'UPDATE_SINGLE_EMAIL':
      return{
        ...state,
        email: action.payload,
        singleMemberEmail: action.payload
      }
    case 'UPDATE_TEAMNAME':
      return {
        ...state,
        teamName: action.payload,
      }
    case 'UPDATE_CAPTAIN':
      return {
        ...state,
        golfers: [action.payload]
      }
    case 'UPDATE_MEMBER_ONE':
      golfers[1] = action.payload;
      return {
        ...state,
        golfers: golfers
      }
    case 'UPDATE_MEMBER_TWO':
      golfers[2] = action.payload;
      return {
        ...state,
        golfers: golfers
      }
    case 'UPDATE_MEMBER_THREE':
      golfers[3] = action.payload;
      return {
        ...state,
        golfers: golfers
      }
    case 'UPDATE_NAME':
      return {
        ...state,
        name: action.payload,
      }
    case 'CLOSE_SNACK':
      return {
        ...state,
        error: false,
        errorMsg: ''
      }
    case 'SELECT_AMFLIGHT':
      return {
        ...state,
        amSelected: action.payload
      }
    case 'SELECT_PMFLIGHT':
      return {
        ...state,
        pmSelected: action.payload
      }
    case 'TEAM_SELECT':
      let t = find(state.existing, ['registrationId', action.payload]);
      return {
        ...state,
        selectedTeam: t.teamInfo.teamName,
        registrationId: action.payload,
        teamName: t.teamInfo.teamName,
        golfers: t.teamInfo.golfers,
        amSelected: t.flightTimes.am,
        pmSelected: t.flightTimes.pm
      }
    case 'UPDATE_POWERPACKS':
      return {
        ...state,
        powerPacks: action.payload
      }
    case 'RESET':
      return {
        ...state,
        email: '',
        teamName: '',
        golfers: [],
        captain: '',
        memberOne: '',
        memberTwo: '',
        memberThree: '',
        name: '',
        singleMemberEmail: '',
        amSelected: false,
        pmSelected: false,
        powerPacks: 0,
        error: false,
        errorMsg: '',
        requestFailed: false,
        selectedTeam: '',
        registrationId: ''
      }
    default:
      break;
  }
}

export class Provider extends React.Component {
  constructor(props){
    super(props);

    this.state={
      amFlights: 0,
      pmFlights: 0,
      availableFlights: false,
      email: '',
      teamName: '',
      golfers: [],
      captain: '',
      memberOne: '',
      memberTwo: '',
      memberThree: '',
      name: '',
      singleMemberEmail: '',
      amSelected: false,
      pmSelected: false,
      ticketPrice: 100,
      powerPackPrice: 0,
      powerPacks: 0,
      existing: [],
      error: false,
      errorMsg: '',
      requestFailed: false,
      selectedTeam: '',
      registrationId: '',
      existingAmFlights: 0,
      existingPmFlights: 0,
      maxAmFlights: 0,
      maxPmFlights: 0,
      captainsFull: true,
      dispatch: action => { this.setState(state => reducer(state, action))}
    }
  }

  componentDidMount(){
    let apiUrl = 'golfclassic/api';
    axios.get(apiUrl + "/init").then(resp => {
      let totalAmFlights = resp.data.baseInfo.maxAmFlights - resp.data.baseInfo.totalAmFlights;
      let totalPmFlights = resp.data.baseInfo.maxPmFlights - resp.data.baseInfo.totalPmFlights;
      this.setState({
        amFlights: totalAmFlights,
        pmFlights: totalPmFlights,
        ticketPrice: resp.data.baseInfo.ticketPrice,
        powerPackPrice: resp.data.baseInfo.powerPackPrice,
        existingAmFlights: resp.data.baseInfo.totalAmFlights,
        existingPmFlights: resp.data.baseInfo.totalPmFlights,
        maxAmFlights: resp.data.baseInfo.maxAmFlights,
        maxPmFlights: resp.data.baseInfo.maxPmFlights,
        existing: resp.data.existing
      });
      let capsFull = true;
      resp.data.existing.map((e) => {
        if (e.teamInfo.golfers.length < 4) {
          capsFull = false;
        }
        return 0;
      })
      if(totalAmFlights > 0 || totalPmFlights > 0){
        this.setState({availableFlights: true, captainsFull: capsFull});
      } else {
        this.setState( {...this.state, availableFlights: false, captainsFull: capsFull});
      }
    }).catch(e => {
      let err = {
        error: "Could not retrieve data from url: " + apiUrl,
        exception: e.data.response.message
      }
      axios({
        method: 'POST',
        url: 'golfclassic/api/error',
        data: err
      })
      this.setState({
        error: true,
        errorMsg: 'Unable to retrieve data from the server. Please contact us at mdfop102golf@gmail.com - ' + e,
        requestFailed: true
      })
    });
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;