import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { PayPalButton } from 'react-paypal-button-v2';
import useStyles from "../../styles/StyledComponents";
import PropTypes from "prop-types";

PaymentForm.propTypes = {
    amSelected: PropTypes.bool,
    cap: PropTypes.bool,
    captain: PropTypes.string,
    email: PropTypes.string,
    memberOne: PropTypes.string,
    memberTwo: PropTypes.string,
    memberThree: PropTypes.string,
    name: PropTypes.string,
    onSuccess: PropTypes.func,
    pmSelected: PropTypes.bool,
    powerPacks: PropTypes.number,
    powerPackPrice: PropTypes.number,
    sing: PropTypes.bool,
    teamName: PropTypes.string,
    ticketPrice: PropTypes.number
}

export default function PaymentForm(props) {
  let { h2, hr, rowHeader, subtitle} = useStyles();
  let {
      amSelected,
      cap,
      golfers,
      email,
      name,
      onSuccess,
      pmSelected,
      powerPackPrice,
      powerPacks,
      sing,
      teamName,
      ticketPrice
  } = props;

  const total =
      golfers[1] && golfers[2] && golfers[3]
      ? ticketPrice * 4
      : golfers[1] && golfers[2]
        ? ticketPrice * 3
        : golfers[1]
          ? ticketPrice * 2
          : ticketPrice;
  const powerPackCost = powerPacks * powerPackPrice;

  const onSuccessfulPayment = (payment, sing) => {
    console.log('Successful payment!', payment);
    onSuccess(payment, sing);
  };

  const onError = (error) =>
    console.log('Erroneous payment OR failed to load script!', error);

  const onCancel = (data) => console.log('Cancelled payment!', data);
  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Typography variant='h2' className={h2}>
          Order Summary
        </Typography>
        <hr className={hr} />
      </Grid>
      <Grid item>
        <Typography variant='body1'>Your Information</Typography>
        <hr className={hr} />
      </Grid>
      <Grid item>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={rowHeader}>Your Email</TableCell>
              <TableCell>{email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>Your Name</TableCell>
              <TableCell>{name}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item>
        <Typography variant='body1'>Team Information</Typography>
        <hr className={hr} />
      </Grid>
      <Grid item>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={rowHeader}>Team Name</TableCell>
              <TableCell>{teamName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>Team Captain</TableCell>
              <TableCell>{golfers[0].name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>Member #1</TableCell>
              <TableCell>{golfers[1]? golfers[1].name : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>Member #2</TableCell>
              <TableCell>{golfers[2] ? golfers[2].name : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>Member #3</TableCell>
              <TableCell>{golfers[3]? golfers[3].name : ""}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>AM Flight</TableCell>
              <TableCell>{amSelected ? 'YES' : 'NO'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={rowHeader}>PM Flight</TableCell>
              <TableCell>{pmSelected ? 'YES' : 'NO'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item>
        {sing && (
          <Typography variant='body1'>
            <strong>Single Player Registration</strong>
            {amSelected
              ? ' (AM Flight Time): $' + ticketPrice
              : ' (PM Flight Time): $' + ticketPrice}
          </Typography>
        )}
        {cap && (
          <Typography variant='body1'>
            <strong>Team Registration</strong>
            {amSelected
              ? ' (AM Flight Time): $' + total
              : ' (PM Flight Time): $' + total}
          </Typography>
        )}
      </Grid>
      <Grid item>
        {powerPacks > 0 && (
          <Typography variant='body1'>
            <strong>Power Packs</strong> (Qty: {powerPacks}): $
            {powerPackCost}{' '}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Typography variant='h4' className={subtitle}>
          Total: ${cap ? powerPackCost + total : powerPackCost + ticketPrice}
        </Typography>
      </Grid>
      <Grid item className={subtitle}>
        <PayPalButton
          amount={cap ? powerPackCost + total : powerPackCost + ticketPrice}
          //shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
          onSuccess={(r) => onSuccessfulPayment(r, sing)}
          onCancel={onCancel}
          onError={onError}
          options={{
            clientId:
              'ARkgXSO_tDjolus2pgynAErNcJb43C9MH0uIVxpY-YxV3BVJHXshwN2JO0qed7yh2ZaxE4WrdMUl-qjZ'
          }} //PRODUCTION
        // options={{clientId: 'AbSOkxC2eSoMXKB1CgB6kB7G06U4J8DoF5x2pvMyAvxgSnaB4Eh9_E3CGZzPBL4EIBQq58HTJgHuMS_o'}} //SANDBOX
        />
      </Grid>
    </Grid>
  );
}
