import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

CustomizedSnackbars.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

function CustomizedSnackbars(props) {
    let { open, onClose, variant, message } = props;
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={5500}
        onClose={onClose}
      >
        <Alert onClose={onClose} severity={variant}>{ message }</Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default CustomizedSnackbars;