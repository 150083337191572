import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { find } from 'lodash';
import PropTypes from "prop-types";
import useStyles from "../../styles/StyledComponents";

CaptainForm.propTypes = {
  captain: PropTypes.string,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  existing: PropTypes.array,
  flight: PropTypes.func,
  memberOne: PropTypes.string,
  memberTwo: PropTypes.string,
  memberThree: PropTypes.string,
  teamName: PropTypes.string
}

export default function CaptainForm(props){
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrMsg, setEmailErrMsg] = React.useState('');
  const [teamNameErr, setTeamNameErr] = React.useState(false);
  const [teamNameErrMsg, setTeamNameErrMsg] = React.useState('');
  const [teamCaptainErr, setTeamCaptainErr] = React.useState(false);
  const [teamCaptainErrMsg, setTeamCaptainErrMsg] = React.useState('');
  const [memberOneErr, setMemberOneErr] = React.useState(false);
  const [memberOneErrMsg, setMemberOneErrMsg] = React.useState('');
  const [memberTwoErr, setMemberTwoErr] = React.useState(false);
  const [memberTwoErrMsg, setMemberTwoErrMsg] = React.useState('');
  const [memberThreeErr, setMemberThreeErr] = React.useState(false);
  const [memberThreeErrMsg, setMemberThreeErrMsg] = React.useState('');
  let {
      asterisk,
      formContainer,
      formItem,
      gridItem,
      h2,
      hr,
      next,
      nextContainer
  } = useStyles();

  let {
      dispatch,
      email,
      existing,
      flight,
      golfers,
      teamName
  } = props;
  
  const valid = (email === '' || emailError) || (teamName === '' || teamNameErr) || ((golfers[0] && golfers[0].name === '') || teamCaptainErr) || memberOneErr || memberTwoErr || memberThreeErr;

  const handleChange = (value, field, dispatch) => {
    switch(field){
      case 'email':
        dispatch({type:'UPDATE_EMAIL', payload:value});
        break;
      case 'teamName':
        dispatch({type:'UPDATE_TEAMNAME', payload:value});
        break;
      case 'teamCaptain':
        dispatch({type:'UPDATE_CAPTAIN', payload:value});
        dispatch({type: 'UPDATE_NAME', payload: value.name})
        break;
      case 'memberOne':
        dispatch({type:'UPDATE_MEMBER_ONE', payload:value});
        break;
      case 'memberTwo':
        dispatch({type:'UPDATE_MEMBER_TWO', payload:value});
        break;
        case 'memberThree':
          dispatch({type:'UPDATE_MEMBER_THREE', payload:value});
          break;
      default:
        break;
    }
  }

  const handleBlur = (field, value) => {
    console.log(value);
    const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const teamNameReg = /^\w/;
    const nameReg = /^[a-zA-Z ]+$/;
    switch(field){
      case 'email':
        if(value === '' || !value || !emailReg.test(value)){
          setEmailError(true);
          setEmailErrMsg("Please provide a valid email address");
        } else{
          setEmailError(false);
          setEmailErrMsg("");
        }
        break;
      case 'teamName':
        if(value === '' || !value || value.length < 3 || !teamNameReg.test(value)){
          setTeamNameErr(true);
          setTeamNameErrMsg('Invalid team name. Must be at least 3 alphanumeric characters (a-z, 0-9)')
        } else{
          let a = find(existing, function(p){
            if(p.teamInfo.teamName.toLowerCase() === value.toLowerCase()){
              return true;
            }})
          if(a){
            setTeamNameErr(true);
            setTeamNameErrMsg('A team with the name ' + value + ' already exists. Please choose a new name');
          } else{
            setTeamNameErr(false);
            return setTeamNameErrMsg('');
          }
        } 
        break;
      case 'teamCaptain':
        if(value === '' || !value || value.length < 4 || !nameReg.test(value)){
          setTeamCaptainErr(true);
          setTeamCaptainErrMsg('Invalid Captain name. Name must be at least 4 characters a-z.')
        } else{
          setTeamCaptainErr(false);
          setTeamCaptainErrMsg('');
        }
        break;
        case 'memberOne':
          if(value && (value.length < 4 || !nameReg.test(value))){
            setMemberOneErr(true);
            setMemberOneErrMsg('Please enter a valid name. Must be at least 4 characters a-z.')
          } else{
            setMemberOneErr(false);
            setMemberOneErrMsg('');
          }
          break;
        case 'memberTwo':
          if(value && (value.length < 4 || !nameReg.test(value))){
            setMemberTwoErr(true);
            setMemberTwoErrMsg('Please enter a valid name. Must be at least 4 characters a-z.')
          } else{
            setMemberTwoErr(false);
            setMemberTwoErrMsg('');
          }
          break;
          case 'memberThree':
            if(value && (value.length < 4 || !nameReg.test(value))){
              setMemberThreeErr(true);
              setMemberThreeErrMsg('Please enter a valid name. Must be at least 4 characters a-z.')
            } else{
              setMemberThreeErr(false);
              setMemberThreeErrMsg('');
            }
            break;
      default:
        break;
    }
  }

  return(
    <React.Fragment>
      <Grid item>
        <Typography variant='h2' className={h2}>Step 2: Team Information</Typography>
        <hr className={hr}/>
      </Grid>
      <Grid item className={gridItem}>
        <Typography variant='body1'>Please enter your team information. <strong>You DO NOT need to register a full team at this time.</strong></Typography>
      </Grid>
      <Grid item className={gridItem}>
        <Typography variant='body1'>Other members can visit the site at a later date and select <em>I'm registering AS A SINGLE PLAYER with a pre-existing team</em> and then select your team or the team captain when they register.</Typography>
      </Grid>
      <form className={formContainer}>
        <Grid container direction='column'> 
          <Grid item>
            <TextField
              color='secondary'
              label='Email Address'
              type='email'
              className={formItem}
              InputLabelProps={{classes:{asterisk: asterisk}}}
              inputProps={{value: email}}
              fullWidth
              required
              onChange={(e) => handleChange(e.currentTarget.value, 'email', dispatch)}
              onBlur={() => handleBlur('email', email)}
              error={emailError}
              helperText={emailError ? emailErrMsg : '' }
            />
          </Grid> 
          <Grid item>
            <TextField
              color='secondary'
              label='Team Name'
              className={formItem}
              InputLabelProps={{classes: {asterisk: asterisk}}}
              inputProps={{value: teamName}}
              fullWidth
              required
              onChange={(e) => handleChange(e.currentTarget.value, 'teamName', dispatch)}
              onBlur={() => handleBlur('teamName', teamName)}
              error={teamNameErr}
              helperText={teamNameErr ? teamNameErrMsg : ''}
            />
          </Grid>
          <Grid item>
            <TextField
              color='secondary'
              label='Team Captain'
              className={formItem}
              InputLabelProps={{classes: {asterisk: asterisk}}}
              inputProps={{value: golfers[0] ? golfers[0].name : ""}}
              fullWidth
              required
              onChange={(e) => handleChange({name: e.currentTarget.value, email: email, captain: true}, 'teamCaptain', dispatch)}
              onBlur={() => handleBlur('teamCaptain', golfers[0].name)}
              error={teamCaptainErr}
              helperText={teamCaptainErr ? teamCaptainErrMsg : ''}
            />
          </Grid>
          <Grid item>
            <TextField
              color='secondary'
              label='Team Member #1'
              className={formItem}
              inputProps={{value: golfers[1] ? golfers[1].name : ""}}
              fullWidth
              onChange={(e) => handleChange({name: e.currentTarget.value, captain: false}, 'memberOne', dispatch)}
              onBlur={() => handleBlur('memberOne', golfers[1].name)}
              error={memberOneErr}
              helperText={memberOneErr ? memberOneErrMsg : ''}
            />
          </Grid>
          <Grid item>
            <TextField
              color='secondary'
              label='Team Member #2'
              className={formItem}
              inputProps={{value: golfers[2] ? golfers[2].name : ""}}
              fullWidth
              onChange={(e) => handleChange({name: e.currentTarget.value, captain: false}, 'memberTwo', dispatch)}
              onBlur={() => handleBlur('memberTwo', golfers[2].name)}
              error={memberTwoErr}
              helperText={memberTwoErr ? memberTwoErrMsg : ''}
            />
          </Grid>
          <Grid item>
            <TextField
              color='secondary'
              label='Team Member #3'
              className={formItem}
              inputProps={{value: golfers[3] ? golfers[3].name : ""}}
              fullWidth
              onChange={(e) => handleChange({name: e.currentTarget.value, captain: false}, 'memberThree', dispatch)}
              onBlur={() => handleBlur('memberThree', golfers[3].name)}
              error={memberThreeErr}
              helperText={memberThreeErr ? memberThreeErrMsg : ''}
            />
          </Grid>
          <Grid item className={nextContainer}>
            <Button
              disabled={valid}
              fullWidth
              style={{marginBottom: 50}}
              className={next}
              onClick={() => flight()}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  )
}