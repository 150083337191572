import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TheSponsors from '../assets/sponsors.png';
import useStyles from "../styles/StyledComponents";

export default function Sponsors(){
  let { supportGridItem, header, img } = useStyles();

  return(
    <Grid item lg={2} md={2} className={supportGridItem}>
      <Grid container direction='column' alignContent='center' spacing={2}>
        <Grid item>
          <Typography variant='h1' className={header}>Our Sponsors</Typography>
          <hr />
        </Grid>
        <Grid item>
          <img className={img} src={TheSponsors} alt='Our Sponsors'/>
        </Grid>
      </Grid>
  </Grid>
  )
}