import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Grid, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import axios from "axios";
import useStyles from "../styles/StyledComponents";
import {Consumer} from "../context";
import {CSVLink} from "react-csv";

function Dashboard() {
    const [registrations, setRegistrations] = useState([]);
    const [captainEmails, setCaptainEmails] = useState([]);
    const [csvData, setCsvData] = useState('');
    const csvLink = useRef();
    const classes = useStyles();

    useEffect(() => {
        axios.get("golfclassic/api/admin/getRegs").then((response) => {
            setRegistrations(response.data);
            let caps = [];
            response.data && response.data.map((registration) => {
                return registration.teamInfo.golfers.map((g) => {
                    if(g.captain && g.email) {
                        caps.push(g.email);
                    }
                    return 0;
                })
            });
            caps = [...new Set(caps)];
            setCaptainEmails(caps);
        }).catch((e) => {
            console.error(e);
        })
    }, [])

    async function exportToCsv() {
        await axios.get('golfclassic/api/admin/registrations/export').then((response) => {
            console.log("Success");
            setCsvData(response.data);
        }).catch((e) => {
            console.error("Failed to export: " + e.getMessage());
        });
        
        csvLink.current.link.click();
    }


    return(
      <Consumer>
          {value => {
              let { existingAmFlights, existingPmFlights, maxAmFlights, maxPmFlights } = value
            return (
              <Grid container direction={'column'} style={{marginBottom: '12%'}}>
                  <Grid item>
                      <Grid container direction={'row'} justifyContent={'center'}>
                          <Grid item>
                              <Typography variant={'h4'}>Registration Dashboard</Typography>
                              <Typography variant={'body1'} style={{textAlign: 'center'}}>Total: {registrations.length}</Typography>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item>
                      <Grid container justifyContent={'space-around'} style={{marginBottom: '10%'}}>
                          <Grid item >
                              <Button className={classes.next} variant={'contained'} onClick={exportToCsv}>Export to CSV</Button>
                              <CSVLink
                                data={csvData}
                                filename='registrations.csv'
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                              />
                          </Grid>
                          <Grid item >
                              <Button className={classes.next} variant={'contained'} component={'a'} href={'mailto:' + captainEmails}>Email Captains</Button>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item>
                      <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                          <Grid item>
                              <TableContainer component={Paper} style={{marginTop: 20, marginBottom: 80, width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
                                  <Table>
                                      <TableHead style={{backgroundColor: '#004f99'}}>
                                          <TableRow>
                                              <TableCell style={{color: 'white'}}>AM Flight ({existingAmFlights}/{maxAmFlights})</TableCell>
                                              <TableCell style={{color: 'white'}}>PM Flight ({existingPmFlights}/{maxPmFlights})</TableCell>
                                              <TableCell style={{color: 'white'}}>Team Name</TableCell>
                                              <TableCell style={{color: 'white'}}>Email</TableCell>
                                              <TableCell style={{color: 'white'}}>Captain</TableCell>
                                              <TableCell style={{color: 'white'}}>Member One</TableCell>
                                              <TableCell style={{color: 'white'}}>Member Two</TableCell>
                                              <TableCell style={{color: 'white'}}>Member Three</TableCell>
                                              <TableCell style={{color: 'white'}}>Powerpacks</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {registrations.map((r) => {
                                              return (
                                                <TableRow key={r.captain + (r.flightTimes.am ? "1" : "2")}>
                                                    <TableCell>{r.flightTimes.am.toString()}</TableCell>
                                                    <TableCell>{r.flightTimes.pm.toString()}</TableCell>
                                                    <TableCell>{r.teamInfo.teamName}</TableCell>
                                                    <TableCell>{r.email}</TableCell>
                                                    <TableCell>{r.teamInfo.golfers[0].name}</TableCell>
                                                    <TableCell>{r.teamInfo.golfers[1]?.name}</TableCell>
                                                    <TableCell>{r.teamInfo.golfers[2]?.name}</TableCell>
                                                    <TableCell>{r.teamInfo.golfers[3]?.name}</TableCell>
                                                    <TableCell>{r.powerpacks}</TableCell>
                                                </TableRow>
                                              )
                                          })}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                          </Grid>
                      </Grid>
                  </Grid>
              </Grid>
            )
          }}
      </Consumer>
    )
}

export default Dashboard;