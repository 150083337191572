import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from 'axios';

import SingleForm from './forms/SingleForm';
import CaptainForm from './forms/CaptainForm';
import FlightForm from './forms/FlightForm';
import SingleInfoForm from './forms/SingleInfoForm'
import PowerPackForm from './forms/PowerPackForm';
import PaymentForm from './forms/PaymentForm';
import CustomizedSnackbars from './CustomSnack';
import PropTypes from "prop-types";
import useStyles from "../styles/StyledComponents";
import Info from "./Info";

RegistrationBody.propTypes = {
  amFlights: PropTypes.number,
  amSelected: PropTypes.bool,
  availableFlights: PropTypes.bool,
  captain: PropTypes.string,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  existing: PropTypes.array,
  memberOne: PropTypes.string,
  memberTwo: PropTypes.string,
  memberThree: PropTypes.string,
  name: PropTypes.string,
  pmFlights: PropTypes.number,
  pmSelected: PropTypes.bool,
  powerPacks: PropTypes.number,
  powerPackPrice: PropTypes.number,
  requestFailed: PropTypes.bool,
  selectedTeam: PropTypes.string,
  singleMemberEmail: PropTypes.string,
  teamName: PropTypes.string,
  ticketPrice: PropTypes.number
}
export default function RegistrationBody(props) {
  const [captainForm, setCaptainForm] = React.useState(false);
  const [flightForm, setFlightForm] = React.useState(false);
  const [singleForm, setSingleForm] = React.useState(false);
  const [singInfo, setSingInfo] = React.useState(false);
  const [powerPackForm, setPowerPackForm] = React.useState(false);
  const [cap, setCap] = React.useState(false);
  const [sing, setSing] = React.useState(false);
  const [paymentForm, setPaymentForm] = React.useState(false);
  const [paymentSuccess, setPaymentSuccess] = React.useState(false);

  let {
    amFlights,
    amSelected,
    availableFlights,
    captain,
    dispatch,
    email,
    existing,
    memberOne,
    memberTwo,
    memberThree,
    name,
    pmFlights,
    pmSelected,
    powerPacks,
    powerPackPrice,
    requestFailed,
    selectedTeam,
    registrationId,
    teamName,
    ticketPrice,
    captainsFull,
    golfers
  } = props;
  let {
    bodyGridItem,
    breadcrumbs,
    breadcrumbBtn,
    lastButton,
    regHeader,
    registerButton
  } = useStyles();

  const goHome = () => {
    setCaptainForm(false);
    setFlightForm(false);
    setSingleForm(false);
    setSingInfo(false);
    setPowerPackForm(false);
    setPaymentForm(false)
    dispatch({ type: 'RESET' })
  }

  const goToFlight = () => {
    setCaptainForm(false);
    setFlightForm(true);
    setSingleForm(false);
    setSingInfo(false);
    setPowerPackForm(false);
    setPaymentForm(false)
  }

  const goToSingInfo = () => {
    setCaptainForm(false);
    setFlightForm(false);
    setSingleForm(false);
    setPowerPackForm(false);
    setPaymentForm(false);
    setSingInfo(true);
  }

  const goToPowerPack = () => {
    setCaptainForm(false);
    setFlightForm(false);
    setSingleForm(false);
    setSingInfo(false);
    setPaymentForm(false)
    setPowerPackForm(true);
  }

  const goToPaymentForm = () => {
    setCaptainForm(false);
    setFlightForm(false);
    setSingleForm(false);
    setSingInfo(false);
    setPowerPackForm(false);
    setPaymentForm(true);
  }

  const onSuccess = async (data, sing) => {
    const mdfop102registrationUrl = sing ?
        'golfclassic/api/update/' + registrationId :
        'golfclassic/api/register';
    const mdfop102errorUrl = 'golfclassic/api/error';
    let registration = {
      isValid: true,
      flightTimes: {
        am: amSelected,
        pm: pmSelected
      },
      email: email,
      teamInfo: {
        teamName: teamName,
        golfers: golfers
      },
      powerpacks: powerPacks,
      total: Number(data.purchase_units[0].amount.value)
    };
    return axios({
      method: sing ? 'PUT' : 'POST',
      url: mdfop102registrationUrl,
      data: registration,
      contentType: 'application/json'
    }).then(function (response) {
      console.log(response.data.registrationId);
      goHome();
      setPaymentSuccess(true);
    }).catch(function (e) {
      let err = {
        error: "Could not POST data to url: " + mdfop102registrationUrl,
        exception: e
      }
      axios({
        method: 'POST',
        url: mdfop102errorUrl,
        data: err
      })
    })
  }

  return (
    <Grid item lg={8} xs={12} className={bodyGridItem}>
      <Grid container direction='column' spacing={2} >
        <Info ticketPrice={ticketPrice}/>
        {!captainForm && !singleForm && !flightForm && !singInfo && !powerPackForm && !paymentForm &&
          <React.Fragment>
            <Grid item>
              <Typography className={regHeader} variant='h2'>Step 1: How Are You Registering?</Typography>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                disableRipple
                disabled={requestFailed || !availableFlights}
                onClick={() => { setCaptainForm(true); setCap(true); setSing(false); }}
                className={registerButton}
              >I'm Registering as a TEAM CAPTAIN</Button>
            </Grid>
            <Grid item className={lastButton}>
              <Button
                variant='contained'
                disableRipple
                disabled={requestFailed || captainsFull}
                onClick={() => { setSingleForm(true); setSing(true); setCap(false); }}
                className={registerButton}
              >I'm Registering as a SINGLE PLAYER with a pre-existing team</Button>
            </Grid>
          </React.Fragment>}
        {captainForm &&
          <React.Fragment>
            <Grid item>
              <Breadcrumbs className={breadcrumbs} >
                <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <CaptainForm dispatch={dispatch} email={email} teamName={teamName} golfers={golfers} captain={captain} memberOne={memberOne} memberTwo={memberTwo} memberThree={memberThree} existing={existing} flight={goToFlight} />
            </Grid>
          </React.Fragment>
        }
        {flightForm &&
          <React.Fragment>
            <Grid item>
              <Breadcrumbs className={breadcrumbs}>
                <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
                <Button className={breadcrumbBtn} onClick={() => { setFlightForm(false); setCaptainForm(true) }}>Team Information</Button>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <FlightForm availableFlights={availableFlights} availableAmFlights={amFlights} availablePmFlights={pmFlights} amSelected={amSelected} pmSelected={pmSelected} goToPowerPack={goToPowerPack} dispatch={dispatch} />
            </Grid>
          </React.Fragment>
        }
        {singleForm &&
          <React.Fragment>
            <Grid item>
              <Breadcrumbs className={breadcrumbs}>
                <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <SingleForm existing={existing} dispatch={dispatch} selectedTeam={selectedTeam} toSingInfo={goToSingInfo} />
            </Grid>
          </React.Fragment>
        }
        {singInfo &&
          <React.Fragment>
            <Grid item>
              <Breadcrumbs className={breadcrumbs}>
                <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
                <Button className={breadcrumbBtn} onClick={() => {
                  setSingleForm(true);
                  setSingInfo(false);
                }}>Select Team</Button>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <SingleInfoForm email={email} name={name} golfers={golfers} dispatch={dispatch} goToPowerPack={goToPowerPack} />
            </Grid>
          </React.Fragment>
        }
        {powerPackForm &&
          <React.Fragment>
            <Grid item>
              {cap &&
                <Breadcrumbs className={breadcrumbs}>
                  <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
                  <Button className={breadcrumbBtn} onClick={() => { setCaptainForm(true); setPowerPackForm(false); }}>Team Information</Button>
                  <Button className={breadcrumbBtn} onClick={() => {
                    setFlightForm(true);
                    setPowerPackForm(false);
                  }}>Flight Times</Button>
                </Breadcrumbs>
              }
              {sing &&
                <Breadcrumbs className={breadcrumbs}>
                  <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
                  <Button className={breadcrumbBtn} onClick={() => {
                    setSingleForm(true);
                    setPowerPackForm(false);
                  }}>Select Team</Button>
                  <Button className={breadcrumbBtn} onClick={() => {
                    setPowerPackForm(false);
                    setSingInfo(true);
                  }}>Your Information</Button>
                </Breadcrumbs>
              }
            </Grid>
            <Grid item>
              <PowerPackForm dispatch={dispatch} powerPacks={powerPacks} powerPackPrice={powerPackPrice} goToPaymentForm={goToPaymentForm} />
            </Grid>
          </React.Fragment>
        }
        {paymentForm &&
          <React.Fragment>
            <Grid item>
              {cap &&
                <Breadcrumbs className={breadcrumbs}>
                  <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
                  <Button className={breadcrumbBtn} onClick={() => { setCaptainForm(true); setPaymentForm(false) }}>Team Information</Button>
                  <Button className={breadcrumbBtn} onClick={() => {
                    setFlightForm(true);
                    setPowerPackForm(false);
                  }}>Flight Times</Button>
                  <Button className={breadcrumbBtn} onClick={() => { setPaymentForm(false); setPowerPackForm(true); }}>Power Packs</Button>
                </Breadcrumbs>
              }
              {sing &&
                <Breadcrumbs className={breadcrumbs}>
                  <Button className={breadcrumbBtn} onClick={() => goHome()}>Home</Button>
                  <Button className={breadcrumbBtn} onClick={() => {
                    setSingleForm(true);
                    setPaymentForm(false);
                  }}>Select Team</Button>
                  <Button className={breadcrumbBtn} onClick={() => {
                    setPaymentForm(false);
                    setSingInfo(true);
                  }}>Your Information</Button>
                  <Button className={breadcrumbBtn} onClick={() => { setPaymentForm(false); setPowerPackForm(true) }}>Power Packs</Button>
                </Breadcrumbs>
              }
            </Grid>
            <Grid item>
              <PaymentForm onSuccess={onSuccess} cap={cap} sing={sing} {...props} />
            </Grid>
          </React.Fragment>
        }
      </Grid>
      <CustomizedSnackbars
        open={paymentSuccess}
        onClose={() => setPaymentSuccess(false)}
        variant='success'
        message='You have successfully registered and payment was received. We look forward to watching you hack it up on the course. Thank you.'
      />
    </Grid>
  )
}