import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette:{
    primary: {
      main: '#ffd200'
    },
    secondary:{
      main: '#004f99'
    }
  },
  typography: {
    tab: {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    }
  }
}); 

export default theme;