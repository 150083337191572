import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import useStyles from "../../styles/StyledComponents";
import PropTypes from "prop-types";

FlightForm.propTypes ={
  amSelected: PropTypes.bool,
  availableFlights: PropTypes.bool,
  availableAmFlights: PropTypes.number,
  availablePmFlights: PropTypes.number,
  dispatch: PropTypes.func,
  goToPowerPack: PropTypes.func,
  pmSelected: PropTypes.bool,
}

export default function FlightForm(props){
  let classes = useStyles();
  let {
    amSelected,
    availableFlights,
    availableAmFlights,
    availablePmFlights,
    dispatch,
    goToPowerPack,
    pmSelected
  } = props;

  return(
    <React.Fragment>
      <Grid item>
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='h2' className={classes.h2}>
              Flight Times
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>{availableFlights ? 'Please select a flight time': 'There are no more available flight times' }</Typography>
            <hr className={classes.hr} />
          </Grid>
          <Grid item>
            <Grid container direction='row'>
              <Grid item lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amSelected}
                      disabled={availableAmFlights === 0 || pmSelected}
                      onClick={() => dispatch({type: 'SELECT_AMFLIGHT', payload: !amSelected})}
                    /> 
                  }
                  label={'A.M. (' + availableAmFlights + ' left)'}
                />
              </Grid>
              <Grid item lg={10}>
                <Table>
                  <TableBody>
                    <TableRow className={classes.oddRow}>
                      <TableCell>6:30 - 7:00 A.M.</TableCell>
                      <TableCell>Registration/Chicken Biscuit Sandwiches from Chick-Fil-A/Donuts/Coffee</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>7:30 A.M.</TableCell>
                      <TableCell>Shotgun Start</TableCell>
                    </TableRow>
                    <TableRow className={classes.oddRow}>
                      <TableCell>11:30 A.M.</TableCell>
                      <TableCell>A.M. Raffle Drawing</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>12:00 - 2:00 P.M.</TableCell>
                      <TableCell>Catered Lunch</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>                
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row'>
              <Grid item lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pmSelected}
                      disabled={availablePmFlights === 0 || amSelected}
                      onClick={() => dispatch({type: 'SELECT_PMFLIGHT', payload: !pmSelected})}
                    />
                  }
                  label={'P.M. (' + availablePmFlights + ' left)' }
                />
              </Grid>
              <Grid item lg={10}>
                <Table>
                  <TableBody>
                    <TableRow className={classes.oddRow}>
                      <TableCell>12:00 - 1:00 P.M.</TableCell>
                      <TableCell>Registration/Lunch from Mission BBQ/Food from the Grill</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>1:00 P.M.</TableCell>
                      <TableCell>Shotgun Start</TableCell>
                    </TableRow>
                    <TableRow className={classes.oddRow}>
                      <TableCell>4:00 P.M.</TableCell>
                      <TableCell>P.M. Raffle Drawing</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.nextContainer}>
            <Button
              disabled={!amSelected && !pmSelected}
              className={classes.next}
              style={{marginBottom: 50}}
              fullWidth
              onClick={goToPowerPack}
            >Next</Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}