import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { find } from 'lodash';
import useStyles from "../../styles/StyledComponents";
import PropTypes from "prop-types";

SingleForm.propTypes = {
  dispatch: PropTypes.func,
  existing: PropTypes.array,
  selectedTeam: PropTypes.string,
  toSingInfo: PropTypes.func
}

export default function SingleForm(props){
  let classes = useStyles();
  let { dispatch, existing, selectedTeam, toSingInfo } = props;
  const [singleSelectTeam, setSingleSelectTeam] = React.useState(selectedTeam);
  const [selectedCaptain, setSelectedCaptain] = React.useState('');
  const [issue, setIssue] = React.useState(false);
  const [issueText, setIssueText] = React.useState('');


  useEffect(() => {
    if(singleSelectTeam){
      let a = find(existing, ['teamInfo.teamName', singleSelectTeam]);
      setSelectedCaptain(a.teamInfo.captain);
    } else{
      setSingleSelectTeam('');
    }
  }, [selectedCaptain, existing, singleSelectTeam])

  const handleChange = (event, props) => {
    let a = find(existing, ['registrationId', props.props.name]);
    if(a.teamInfo.golfers.length === 4){
      setIssue(true);
      setIssueText('There are no available spots on ' + a.teamInfo.golfers[0].name + '\'s team: ' + a.teamInfo.teamName);
      setSelectedCaptain('');
      setSingleSelectTeam('');
    } else{
      setIssue(false);
      setIssueText('');
      setSingleSelectTeam(event.target.value);
      setSelectedCaptain(a.teamInfo.golfers[0]);
      dispatch({type: 'TEAM_SELECT', payload: props.props.name})
    }
  }

  return(
    <React.Fragment>
      <Grid container spacing={2} direction='column'>
        <Grid item>
          <Typography variant='h2' className={classes.h2}>Step 2: Pick Your Team or Captain</Typography>
        </Grid>
        <Grid item>
          {issue && <Typography variant='body1' className={classes.errorText}>{issueText}</Typography>}
          <hr className={classes.hr} />
        </Grid>
        <Grid item>
          <InputLabel id='team-name-label'>Select Team</InputLabel>
          <Select labelId='team-name-label' fullWidth onChange={(event, props) => handleChange(event, props)} inputProps={{value: singleSelectTeam}}>
            {existing.map(team => {
              return team.teamInfo.golfers.length < 4 ?
                <MenuItem value={team.teamInfo.teamName} name={team.registrationId} key={team.teamInfo.id}>{team.teamInfo.teamName + " | " + team.teamInfo.golfers[0].name}</MenuItem> : null})}

          </Select>
        </Grid>
        <Grid item className={classes.nextContainer}>
          <Button
            disabled={singleSelectTeam === '' && selectedCaptain === ''}
            fullWidth
            style={{marginBottom: 50}}
            className={classes.next}
            onClick={() => toSingInfo()}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </React.Fragment> 
  )
}