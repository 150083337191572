import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import useStyles from "../../styles/StyledComponents";
import PropTypes from "prop-types";

SingleInfoForm.propTypes = {
  dispatch: PropTypes.func,
  email: PropTypes.string,
  goToPowerPack: PropTypes.func,
  memberOne: PropTypes.string,
  memberTwo: PropTypes.string,
  memberThree: PropTypes.string,
  name: PropTypes.string
}

export default function SingleInfoForm(props) {
  const [nameErr, setNameErr] = React.useState(false);
  const [nameErrMsg, setNameErrMsg] = React.useState("");
  const [emailErr, setEmailErr] = React.useState(false);
  const [emailErrMsg, setEmailErrMsg] = React.useState("");

  let { dispatch, email, goToPowerPack, name, golfers } = props;
  let { asterisk, formItem, h2, hr, next, nextContainer } = useStyles();

  const handleChange = (value, field, dispatch) => {
    switch(field){
      case 'email':
        dispatch({type:'UPDATE_SINGLE_EMAIL', payload:value});
        break;
      case 'name':
        dispatch({type:'UPDATE_NAME', payload:value});
        break;
      default:
        break;
    }
  }

  const handleBlur = (field, value, dispatch) => {
    const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const nameReg = /^[a-zA-Z ]+$/;
    switch(field){
      case 'email':
        if(value === '' || !value || !emailReg.test(value)){
          setEmailErr(true);
          setEmailErrMsg("Please provide a valid email address");
        } else{
          setEmailErr(false);
          setEmailErrMsg("");
        }
        break;
      case 'name':
        if(value.name === '' || !value.name || value.name.length < 4 || !nameReg.test(value.name)){
          setNameErr(true);
          setNameErrMsg('Name must be at least 4 characters (a-z)')
        } else{
          setNameErr(false);
          setNameErrMsg('');
          if(!golfers[1]) {
            dispatch({type: 'UPDATE_MEMBER_ONE', payload: value})
          } else if (!golfers[2]) {
            dispatch({type: 'UPDATE_MEMBER_TWO', payload: value})
          } else if(!golfers[3]) {
            dispatch({type: 'UPDATE_MEMBER_THREE', payload: value})
          }
        }
        break;
      default:
        break;
    }
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Typography variant='h2' className={h2}>Step 3: Your Information</Typography>
        <hr className={hr} />
      </Grid>
      <Grid item>
        <TextField
          label='Email Address'
          color='secondary'
          type='email'
          className={formItem}
          fullWidth
          required
          inputProps={{value: email}}
          InputLabelProps={{classes:{asterisk: asterisk}}}
          onChange={(e) => handleChange(e.currentTarget.value, 'email', dispatch)}
          onBlur={(e) => handleBlur('email', e.currentTarget.value, dispatch)}
          error={emailErr}
          helperText={emailErr ? emailErrMsg : ''}
        />
      </Grid>
      <Grid item>
        <TextField
          label='Name'
          color='secondary'
          className={formItem}
          fullWidth
          required
          inputProps={{value: name.name}}
          InputLabelProps={{classes: {asterisk: asterisk}}}
          onChange={(e) => handleChange(e.currentTarget.value, 'name', dispatch)}
          onBlur={(e) => handleBlur('name', {name: e.currentTarget.value, email: email, captain:false}, dispatch)}
          error={nameErr}
          helperText={nameErr ? nameErrMsg : ''}
        />
      </Grid>
      <Grid item className={nextContainer}>
        <Button
          disabled={name === '' || email === '' || emailErr || nameErr}
          fullWidth
          style={{marginBottom: 50}}
          className={next}
          onClick={() => goToPowerPack()}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  )
}