import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useStyles from "../styles/StyledComponents";

import FOCAC from '../assets/FOCAC.jpeg';
import steadfast from '../assets/steadfast.jpg';
import Mission_logo from '../assets/Mission_logo.png';
import pathfinders_logo from '../assets/pathfinders_logo.jpg';

export default function Support(props) {
  let { img, header, subtitle, support, supportGridItem } = useStyles();
  let { matches } = props;

  return(
    <Grid item lg={2} md={2} className={supportGridItem}>
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='h1' className={header}>We Proudly Support</Typography>
          <hr />
        </Grid>
        {/* -----FRIENDS OF THE CHILD ADVOCACY CENTER SECTION----- */}
        <Grid item className={support}>
          <img className={img} src={FOCAC} alt='Friends of the Child Advocacy Center logo'/>
        </Grid>
        <Grid item>
          <Typography variant='subtitle1' className={subtitle}>Friends of the Child Advocacy Center Foundation</Typography>
        </Grid>
        {/*/!* -----STEADFAST SECTION----- *!/*/}
        <Grid item className={support}>
          <img className={img} src={steadfast} alt='Steadfast logo'/>
        </Grid>
        <Grid item>
          <Typography variant='subtitle1' style={{marginTop: matches ? '25px' : null}} className={subtitle}>Steadfast</Typography>
        </Grid>
        {/* -----MISSION 10-42 SECTION----- */}
        <Grid item className={support}>
          <img className={img} src={Mission_logo} alt='Mission 10-42 logo'/>
        </Grid>
        <Grid item>
          <Typography variant='subtitle1' className={subtitle}>Mission 10-42</Typography>
        </Grid>
        {/*/!* -----PATHFINDERS FOR AUTISM SECTION----- *!/*/}
        <Grid item className={support}>
          <img className={img} src={pathfinders_logo} alt='Pathfinders for Autism logo'/>
        </Grid>
        <Grid item>
          <Typography variant='subtitle1' className={subtitle}>Pathfinders For Autism</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}