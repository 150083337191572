import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from './context';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import theme from './assets/Theme';
import Header from './components/Header';
import Footer from './components/Footer';
import RegisterPage from './page/RegisterPage';
import Dashboard from "./admin/Dashboard";

function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
          <Router>
              <Header/>
              <Routes>
                  <Route exact path={'/'} element={<RegisterPage />}/>
                  <Route exact path={'/dashboard'} element={<Dashboard />} />
              </Routes>
              <Footer/>
          </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
