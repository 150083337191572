import { makeStyles } from '@material-ui/core/styles';
import {amber, green} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    appbar: {
        zIndex: theme.zIndex.modal + 1
    },
    asterisk: {
        color: '#d50000'
    },
    bodyGridItem: {
        paddingLeft: 15,
        paddingRight: 15
    },
    boldFont: {
      fontWeight: 'bold'
    },
    breadcrumbs: {
        backgroundColor: theme.palette.secondary.main,
        marginBottom: 10,
        color: theme.palette.primary.main
    },
    breadcrumbBtn: {
        textTransform: 'none',
        color: theme.palette.primary.main
    },
    drawerBackground: {
        backgroundColor: theme.palette.secondary.main
    },
    drawerIcon: {
        height: "50px",
        width: "50px",
        color: theme.palette.primary.main
    },
    drawerIconContainer: {
        "&:hover": {
            backgroundColor: "transparent"
        },
        marginLeft: "auto"
    },
    drawerItem: {
        ...theme.typography.tab,
        color: theme.palette.primary.main,
        opacity: 0.7
    },
    drawerItemSelected: {
        "& .MuiListItemText-root": {
            opacity: 1
        }
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    errorText: {
        color: '#d50000'
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        width: "100%",
        position: "relative"
    },
    formContainer: {
        marginTop: 15,
        marginBottom: 50
    },
    formItem: {
        minHeight: 65
    },
    gridItem: {
        marginTop: 15
    },
    h2: {
        fontSize: '2rem',
        fontWeight: 500
    },
    header: {
        fontSize: '2.5rem',
        fontWeight: 500,
        textAlign: 'center'
    },
    headerLogo: {
        height: "7em",
        [theme.breakpoints.down("md")]: {
            height: "6em"
        },
        [theme.breakpoints.down("xs")]: {
            height: "4.5em"
        }
    },
    headerLogoContainer: {
        paddingTop:0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: '1.5em',
        "&:hover": {
            backgroundColor: "transparent"
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: '0.75em'
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "0.25em"
        }
    },
    hr: {
        borderTop: '1px solid rgba(0,0,0,.1)'
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    img: {
        width: '100%',
        height: 'auto'
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    lastButton: {
        marginBottom: 150
    },
    logo: {
        height: 50,
        width: 50
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    next: {
        textTransform: 'none',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.primary.main
        },
        '&:disabled': {
            backgroundColor: '#9e9e9e',
            color: '#000'
        }
    },
    nextContainer: {
        marginBottom: 50
    },
    oddRow: {
        backgroundColor: 'rgba(0,0,0,.05)'
    },
    powerPacks: {
        minWidth: 60
    },
    regHeader: {
        fontSize: '2rem',
        fontWeight: 500
    },
    registerButton: {
        textTransform: 'none',
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.light,
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.primary.main
        }
    },
    rowHeader: {
        fontWeight: 'bold'
    },
    sponsor: {
        fontSize: '1.5rem',
        fontWeight: 500,
        textAlign: 'center'
    },
    subtitle: {
        textAlign: 'center'
    },
    success: {
        backgroundColor: green[600]
    },
    support: {
        marginTop:35
    },
    supportGridItem:{
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 50,
        paddingTop: 15
    },
    tab: {
        ...theme.typography.tab,
        minWidth: 10,
        color: theme.palette.primary.main,
        marginRight: "25px"
    },
    tabContainer: {
        marginLeft: "auto"
    },
    toolbar: {
        backgroundColor: theme.palette.secondary.main
    },
    toolbarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: "3em",
        [theme.breakpoints.down("md")]: {
            marginBottom: "2em"
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: "1.25em"
        }
    },
    warning: {
        backgroundColor: amber[700]
    },
}))

export default useStyles;